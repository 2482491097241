import { GetServerSidePropsContext } from 'next';
import React, { useState, useEffect } from 'react';
import Error from 'next/error';
import Head from 'next/head';
import Col from 'react-bootstrap/Col';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { localeFiles } from '../../data/constants';
import { pages } from '../../data/pages';
import PageDefault from '../../components/templates/PageDefault';
import StaticContent from '../../components/templates/StaticContent';

interface PageProps {
  slug: string;
}

const Page = (props: PageProps) => {
  const { t } = useTranslation(['common', 'pages']);
  const router = useRouter();
  const { locale } = router;
  const { slug } = props;
  const sections = (!!locale && pages[locale][slug]) || [
    {
      h1: t(`pages:${slug}:title`),
      children: [t(`pages:${slug}:description`)],
    },
  ];

  if (t(`pages:${slug}:title`) === `${slug}.title`) {
    return <Error statusCode={404} />;
  }

  return (
    <>
      <Head>
        <title>
          {t(`pages:${slug}:title`)} | {t('app-name')}
        </title>
        <meta name="description" content={t(`pages:${slug}:description`)} />
      </Head>

      <PageDefault>
        {sections.map((section: any, i: number) => (
          <StaticContent
            key={String(i)}
            className={section.class}
            h1={section.h1}
            h2={section.h2}
            s={section.s}
            imgs={section.imgs}
            p={section.children}
            html={section.html}
            full={section.full || (section.children || []).length === 1}
            hero={section.hero}
            small={section.narrow}
            columns={section.columns}
            figures={section.figures}
            anchor={section.anchor}
          />
        ))}
      </PageDefault>
    </>
  );
};

export default Page;

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const { params: { slug } = {}, locale } = context;

  return {
    props: {
      slug,
      ...(await serverSideTranslations(locale!, localeFiles)),
    },
  };
}
